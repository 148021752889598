jQuery(document).ready(function($) {

  //Custom Sticky Headers
  ////////////////
  var $header = $('.header-wrapper').find('#header.header-builder');

  $(window).scroll(function(){

      if ($(window).scrollTop() > '0') {
        $header.addClass('sticky');
        $('body').addClass('with-sticky-header');
      }
      else {
         $header.removeClass('sticky');
         $('body').removeClass('with-sticky-header');
      }
  });


  var onResize = function() {
    var $headheight = $header.height();
    $('body').css({"padding-top": $headheight});
  }


  // Initial padding for Fixed Headers
  onResize();
  // Recheck on resize
  $(window).resize(onResize);

  
 //Fieldset Legend heights
  ////////////////
  var $fieldset = $('.battery-spec__fieldset');
 
  var fieldsetResize = function() { 
    $fieldset.each(function() {
      var $fieldsetHeadHeight = $(this).find('legend').outerHeight();
      $(this).css({"padding-top": $fieldsetHeadHeight + 30});
    });
  }
  // Initial padding for Fieldset Headers
  fieldsetResize();
   // Recheck on resize
  $(window).resize(fieldsetResize);




  // Brochure Products
  $(function() {
    var $noSale = $('.product_tag-nosale .product-page_main');
    var $productPrice =  $noSale.find('.price');
    var $productMessage = $('<p class="brouchure-product__message">This product is available upon request.<br />Please <a href="/contact-us">get in touch</a> to discuss requirements.</p>');
    var $noSaleLists = $('li.product_tag-nosale');
    var $productPriceLists =  $noSaleLists.find('.price');
    var $productMessageLists = $('<span class="price">Available To Order</span>');

    $productPrice.after($productMessage);
    $productPrice.remove();

    $productPriceLists.remove();
    $noSaleLists.find('.add-links-wrap').before($productMessageLists);

  });

  document.addEventListener( 'wpcf7invalid', function( event ) {
    setTimeout( function() {
      $('html').stop().animate({
        scrollTop: $('.wpcf7-not-valid').eq(0).offset().top - 140 - $header.height(),
      }, 500, 'swing');
    }, 100);
  }, false );

});
